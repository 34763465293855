
  body.docs > main > aside nav {
    margin-top: 4.2em;
  }
@media (max-width: 1023px) {
    body.docs > main > aside nav {
      margin-top: 2em;
      border-top: 2px solid var(--color-code-background);
  }
    }
body.docs > main > aside ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
body.docs > main > aside ul li {
      margin-bottom: 0.4em;
    }
body.docs > main > aside {

  --h3-font-size: 1.3em;
}
body.docs > main > aside h3 {
    margin-top: 1.5em;
    margin-bottom: 0.8em;
    text-align: left;
  }
body.docs > main > aside a[aria-current] {
    font-weight: bold;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9fY29tcG9uZW50cy9kb2N1bWVudGF0aW9uL3NpZGViYXIuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7RUFDRTtJQUNFLGlCQUFpQjtFQU1uQjtBQUpFO0lBSEY7TUFJSSxlQUFlO01BQ2Ysa0RBQWtEO0VBRXREO0lBREU7QUFHRjtJQUNFLHFCQUFxQjtJQUNyQixjQUFjO0lBQ2QsZUFBZTtFQUtqQjtBQUhFO01BQ0Usb0JBQW9CO0lBQ3RCO0FBakJKOztFQW9CRSxxQkFBcUI7QUFXdkI7QUFURTtJQUNFLGlCQUFpQjtJQUNqQixvQkFBb0I7SUFDcEIsZ0JBQWdCO0VBQ2xCO0FBRUE7SUFDRSxpQkFBaUI7RUFDbkIiLCJmaWxlIjoic3JjL19jb21wb25lbnRzL2RvY3VtZW50YXRpb24vc2lkZWJhci5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJib2R5LmRvY3MgPiBtYWluID4gYXNpZGUge1xuICAmIG5hdiB7XG4gICAgbWFyZ2luLXRvcDogNC4yZW07XG5cbiAgICBAbWVkaWEgKC0tbmF2LW1pbmltYWwpIHtcbiAgICAgIG1hcmdpbi10b3A6IDJlbTtcbiAgICAgIGJvcmRlci10b3A6IDJweCBzb2xpZCB2YXIoLS1jb2xvci1jb2RlLWJhY2tncm91bmQpO1xuICAgIH1cbiAgfVxuXG4gICYgdWwge1xuICAgIGxpc3Qtc3R5bGUtdHlwZTogbm9uZTtcbiAgICBtYXJnaW4tbGVmdDogMDtcbiAgICBwYWRkaW5nLWxlZnQ6IDA7XG5cbiAgICAmIGxpIHtcbiAgICAgIG1hcmdpbi1ib3R0b206IDAuNGVtO1xuICAgIH1cbiAgfVxuXG4gIC0taDMtZm9udC1zaXplOiAxLjNlbTtcblxuICAmIGgzIHtcbiAgICBtYXJnaW4tdG9wOiAxLjVlbTtcbiAgICBtYXJnaW4tYm90dG9tOiAwLjhlbTtcbiAgICB0ZXh0LWFsaWduOiBsZWZ0O1xuICB9XG5cbiAgJiBhW2FyaWEtY3VycmVudF0ge1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICB9XG59XG4iXX0= */